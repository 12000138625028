import axios from 'axios'

import config from '../Config.json'

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.withCredentials = true

export const GetEulaText = async () => {
  return await axios.get(`${config.API_URL}/user/eula/`)
}

export const GetTermsText = async () => {
  return await axios.get(`${config.API_URL}/user/terms/`)
}

export const GetPrivacyText = async () => {
  return await axios.get(`${config.API_URL}/user/privacy/`)
}

export const AcceptEula = async () => {
  return await axios.get(`${config.API_URL}/user/accept-eula/`, { withCredentials: true })
}

export const AcceptTerms = async () => {
  return await axios.get(`${config.API_URL}/user/accept-terms/`, { withCredentials: true })
}

export const AcceptPrivacy = async () => {
  return await axios.get(`${config.API_URL}/user/accept-privacy/`, { withCredentials: true })
}

export const GetNewAnonDataId = async () => {
  // get an anonymous id
  const { data } = await axios.get(`${config.API_URL}/variant/get-random-id/`, { withCredentials: true })
  return data
}

export const CheckAnonDataId = async (anon_data_id: string) => {
  // check if the anonymous data id is valid
  const { data } = await axios.post(
        `${config.API_URL}/variant/check-anonymous-id/`,
        { anon_data_id },
        { withCredentials: true })
  return data
}

export const GetUserData = async () => {
  // get user data from LTI connection
  const { data } = await axios.get(`${config.API_URL}/user/base/`, { withCredentials: true })
  return data
}

export const GetTtamAuth = async () => {
  // get ttam access token
  const { data } = await axios.get(`${config.API_URL}/user/ttam-auth/`, { withCredentials: true })
  return data
}

export const GetVariantsByPosition = async (dataset_id: string, contig: string, position: number) => {
  // get variants by position
  // Don't actually use at the moment

  const { data } = await axios.post(
        `${config.API_URL}/variant/get-vars-by-position/`,
        { dataset_id, contig, position },
        { withCredentials: true })
  return data
}

export const GetVariantsById = async (dataset_id: string, variant_ids: string[]) => {
  // get variants by position
  const { data } = await axios.post(
        `${config.API_URL}/variant/get-vars-by-id/`,
        { dataset_id, variant_ids },
        { withCredentials: true })
  return data
}

export const GetVariantsByGene = async (dataset_id: string, gene: string) => {
  // get variants by position
  const { data } = await axios.post(
        `${config.API_URL}/variant/get-vars-by-gene/`,
        { dataset_id, gene },
        { withCredentials: true })
  return data
}

export const GetDataSourceInfo = async () => {
  const { data } = await axios.get(`${config.API_URL}/user/get-data-source-info/`, { withCredentials: true })
  return data
}

export const TranslateSample = async (dataset_id: string, gene: string, reference: string) => {
  // get variants by position
  const { data } = await axios.post(
        `${config.API_URL}/interpret/sample/`,
        { dataset_id, gene, reference },
        { withCredentials: true })
  return data
}

export const TranslateSamples = async (dataset_id: string, genes: string[], reference: string) => {
  // get variants by position
  const { data } = await axios.post(
        `${config.API_URL}/interpret/samples/`,
        { dataset_id, genes, reference },
        { withCredentials: true })
  return data
}

export const GetLtiInfo = async () => {
  const { data } = await axios.get(`${config.API_URL}/lti/course/config`, { withCredentials: true })
  return data
}

export const UpdateLookupGenes = async (genes: string[]) => {
  const { data } = await axios.post(
        `${config.API_URL}/user/set-lookup-genes/`,
        { genes },
        { withCredentials: true })
  return data
}

export const UpdateInterpretGenes = async (genes: string[]) => {
  const { data } = await axios.post(
        `${config.API_URL}/user/set-interpret-genes/`,
        { genes },
        { withCredentials: true })
  return data
}

export const UpdateTranslationTableGenes = async (genes: string[]) => {
  const { data } = await axios.post(
        `${config.API_URL}/user/set-translation-genes/`,
        { genes },
        { withCredentials: true })
  return data
}

export const UpdatePharmacogenomicsReportGenes = async (genes: string[]) => {
  const { data } = await axios.post(
        `${config.API_URL}/user/set-pharmacogenomics-report-genes/`,
        { genes },
        { withCredentials: true })
  return data
}

export const UpdatePharmacogenomicsReportSettings = async ( genes: { [key: string]: number } ) => {
  const { data } = await axios.post(
        `${config.API_URL}/user/set-pharmacogenomics-report-settings/`,
        { genes },
        { withCredentials: true })
  return data
}

export const UpdateActivities = async (activities: string[]) => {
  const { data } = await axios.post(
        `${config.API_URL}/user/set-activities/`,
        { activities },
        { withCredentials: true })
  return data
}

export const Logout = async () => {
  const { data } = await axios.get(`${config.API_URL}/user/logout/`, { withCredentials: true })
  return data
}

// export const ContributeData = async () => {
//   const { data: response } = await axios.get(`${config.API_URL}/user/contribute-data/`, { withCredentials: true })
//   return response
// }
