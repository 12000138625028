import React from 'react'

import { useDataSource } from '../../context/data_source_context'
import { GetVariantsByGene, TranslateSample } from '../../api'

import { Space, Spin, Table, Divider, List, Row, Col, Button, notification, Modal } from 'antd'

import { useUser } from '../../context/user_context'

export const replaceUnderscoreWithHyphen = (genotype: string) => {
  return genotype.replace(/_/g, '-')
}

export const TranslationTable = () => {
  const [lookupGene, setLookupGene] = React.useState('')
  const [tableData, setTableData] = React.useState([] as any[])
  const [tableColumns, setTableColumns] = React.useState([] as any[])
  const [trueDiplotype, setTrueDiplotype] = React.useState('')
  const [trueHaplotypes, setTrueHaplotypes] = React.useState([] as any[])
  const [referenceHaplotype, setReferenceHaplotype] = React.useState('')
  const [selectedHaplotypes, setSelectedHaplotypes] = React.useState([] as any[])
  const [isLoading, setIsLoading] = React.useState(false)
  const [showResult, setShowResult] = React.useState(false)
  const [showMe, setShowMe] = React.useState(false)

  const { dataSource } = useDataSource()
  const { user } = useUser()

  const checkAnswer = () => {
    // join the original and reversed selectedHaplotypes orders
    const selected_diplotype = selectedHaplotypes.join('/')
    const rev_selected_diplotype = selectedHaplotypes.reverse().join('/')
    if (selected_diplotype === trueDiplotype || rev_selected_diplotype === trueDiplotype) {
      notification.success({
        message: 'Correct!',
        description: 'You are correct!'
      })
    } else {
      notification.error({
        message: 'Incorrect!',
        description: 'You are incorrect - please try again!'
      })
    }
  }

  const addToSelectedHaplotypes = (haplotype: string) => {
    // check if length of non-? is < 2
    if (selectedHaplotypes.filter(h => h !== '?').length < 2) {
      setSelectedHaplotypes([...selectedHaplotypes, haplotype])
    } else {
      notification.error({
        message: 'Error',
        description: 'You can only select 2 haplotypes'
      })
    }
  }

  const removeFromSelectedHaplotypes = (haplotype: string, index: number) => {
    setSelectedHaplotypes(selectedHaplotypes.filter((h, i) => i !== index))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    setShowResult(false)
    translateGene(lookupGene)
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLookupGene(e.target.value)
  }

  const replacenNullGenotype = (genotype: string) => {
    if (genotype == '') {
      return '-/-'
    } else {
      return genotype
    }
  }

  const removeStar = (genotype: string) => {
    const new_geno = parseInt(genotype.replace('*', ''))
    // test if NaN
    if (isNaN(new_geno)) {
      return genotype
    } else {
      return new_geno
    }
  }

  const translateGene = (lookup: string) => {
    const data_source = (dataSource != null) ? dataSource.data_source : ''
    TranslateSample(data_source, lookup, 'GRCH38').then(res => {
      console.log(res)
      // if ('status_code' in res) {
      //   Modal.warning({
      //     title: `23andMe Error ${res.status_code}: ${JSON.parse(res.text).type[0]}`,
      //     content: res.text ? JSON.parse(res.text).message[0] : 'An error occurred. Please try again later.',
      //     onOk: () => setLookupGene(""),
      //   });
      //   setIsLoading(false)
      //   return;
      // }

      const columns = [
        {
          title: '',
          dataIndex: 'haplotype_name',
          key: 'haplotype_name',
          align: 'left',
          render: (text: string) => {
            if (text != "Your Data") {
              return (
                                <>
                                    <Button
                                    type="primary"
                                    size="small"
                                    >Select</Button>  {text}
                                </>
              )
            } else {
              return (
                <>
                  <div style={{"textAlign":"right"}}>{text}</div>
                </>
              )
            }
          }

        }
      ]
      const row_1: any = {
        haplotype_name: "Your Data",
        key: 0
      }
      for (const [rsid, genotype] of Object.entries(res.genotypes)) {
        columns.push({
          title: rsid,
          dataIndex: rsid,
          key: rsid,
          align: 'center',
          render: (text: string) => <>{text}</>
        })
        row_1[rsid] = replacenNullGenotype(genotype as string)
      }
      const rows = res.table.map((row: any, index: number) => {
        row.key = index + 1
        return row
      }).sort((a: any, b: any) => ((removeStar(a.haplotype_name) < removeStar(b.haplotype_name)) ? -1 : 1))
      setTableColumns(columns)
      setTableData([row_1, ...rows])
      setReferenceHaplotype(res.reference_haplotype)
      setTrueDiplotype(replaceUnderscoreWithHyphen(res.diplotype))
      setTrueHaplotypes((res.haplotypes).map((str: string) => replaceUnderscoreWithHyphen(str)))
      setIsLoading(false)
      setShowResult(true)
    }).catch((err) => {
      setIsLoading(false)
      console.log(err)
    })
  }

  const translationTable = () => {
    return (
            <Table
                columns={tableColumns}
                dataSource={tableData}
                size="small"
                pagination={false}
                rowClassName={(record, index) => {
                  if (record.haplotype_name == "Your Data") {
                    return 'translation-table-your-data';
                  }
                  return trueHaplotypes.includes(record.haplotype_name) && showMe ? 'translation-table-true-haplotype' : '';
                }}
                onRow={(record: any) => {
                  return {
                    onClick: () => {
                      if (record.haplotype_name != "Your Data") {
                        addToSelectedHaplotypes(record.haplotype_name)
                      }
                    }
                  }
                }
                }
            />
    )
  }

  React.useEffect(() => {
    if (showResult) {
      setShowResult(false)
      setIsLoading(true)
      translateGene(lookupGene)
    }
  }, [dataSource])

  return (
        <>
            <Divider orientation="left" >Call and Interpret Haplotypes in a Gene</Divider>
            <Row
            gutter={[24, 0]}
            justify="space-around">
                <Col span={10} offset={2}>
                    <form onSubmit={handleSubmit}>
                        <label>
                        <select onChange={handleChange} value={lookupGene} className="field-size-lg">
                            <option value="" key="None"></option>
                            {user?.lti_course.translation_table_genes.map((gene, index) => {
                              return (
                                    <option value={gene.gene} key={gene.id}>{gene.gene}</option>
                              )
                            })}
                        </select>
                        </label>
                        <Button type="primary" htmlType="submit">Get Translation Table</Button>
                    </form>
                </Col>
                {showResult &&
                <Col span={12}>
                    <Row>Your Answer&nbsp;
                            {selectedHaplotypes[0] &&
                                <Button
                                type="primary"
                                onClick={() => { removeFromSelectedHaplotypes(selectedHaplotypes[0], 0) }}
                                >{selectedHaplotypes[0]}</Button>
                            }
                            {!selectedHaplotypes[0] &&
                                <Button type="primary">?</Button>
                            }
                           &nbsp;/&nbsp;
                            {selectedHaplotypes[1] &&
                                <Button
                                type="primary"
                                onClick={() => { removeFromSelectedHaplotypes(selectedHaplotypes[1], 1) }}
                                >{selectedHaplotypes[1]}</Button>
                            }
                            {!selectedHaplotypes[1] &&
                                <Button type="primary">?</Button>
                            }&nbsp;&nbsp;&nbsp;
                            <Button
                            type="primary"
                            onClick={() => { checkAnswer() }}
                            >Check Your Work!</Button>
                    </Row>
                </Col>
                }
            </Row>
            {isLoading
              ? <Row gutter={[24, 0]} justify="space-around">
            <Col span={12}>
            <Spin />
            </Col>
            </Row>
              : null}
            {showResult &&
            <>
                <Divider orientation='left'>Translation Table</Divider>
                <Row
                 gutter={[24, 0]}
                 justify="space-around"
                 className={trueHaplotypes.includes(referenceHaplotype) && showMe ? 'translation-table-true-haplotype' : ''}>
                    <Col span={22}>
                        <b>Reference Haplotype:&nbsp;</b>
                        <Button type="primary" size="small" onClick={() => { addToSelectedHaplotypes(referenceHaplotype) }}>Select</Button>
                            <span>    {referenceHaplotype}</span>
                    </Col>
                </Row>
                <Row
                    gutter={[24, 0]}
                    justify="space-around"
                    >
                    <Col span={22}>
                        {translationTable()}
                    </Col>
                </Row>
                <Divider />
                <Row
                    gutter={[24, 0]}
                    justify="space-around">
                    <Col span={22}>
                        <Button type="primary" onClick={() => { setShowMe(!showMe) }}>{showMe ? 'Hide True Haplotypes' : 'Help Me!'} </Button>
                    </Col>
                </Row>
                </>
            }
        </>
  )
}
